var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-card',{attrs:{"color":"#F5F5F5","flat":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('span',{staticStyle:{"font-family":"'IBM Plex Sans Thai'","font-style":"normal","font-weight":"600","font-size":"24px","line-height":"40px"}},[_vm._v(" รายงานประจำปี ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('DialogAnnualReports',{on:{"getAll":_vm.getAll}}),_c('DialogAnnualReportsEdit',{attrs:{"itemEdit":_vm.itemEdit,"openDialogEdit":_vm.openDialogEdit},on:{"getAll":_vm.getAll,"closeDialogEdit":_vm.closeDialogEdit}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"pa-3 mt-4",attrs:{"color":"#F5F5F5","flat":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"pa-3",attrs:{"width":"100%","flat":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBrandTh,"items":_vm.items,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.updatedAt))+" ")]}},{key:"item.cover_img_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-1",attrs:{"src":item.cover_img_url,"width":"120px"}})]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.DownloadDocument(item.id)}}},[_vm._v("mdi-download")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])]}},(_vm.isWindowWidthGreaterThan768)?{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody"},on:{"change":function($event){return _vm.DragItems(props.items)}}},_vm._l((props.items),function(item,index){return _c('tr',{key:index},[(_vm.isWindowWidthGreaterThan768)?_c('td',[_c('v-icon',{staticClass:"page__grab-icon",attrs:{"small":""}},[_vm._v(" mdi-arrow-all ")])],1):_vm._e(),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(_vm.convertDate(item.updatedAt))+" ")]),_c('td',{staticClass:"text-start"},[_c('v-img',{staticClass:"ma-1",attrs:{"src":item.cover_img_url,"width":"120px"}})],1),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(item.name_th)+" ")]),_c('td',{staticClass:"text-start"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])],1)],1)])}),0)]}}:null],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <ManageAnnualReports />
  </div>
</template>
<script>
import ManageAnnualReports from "@/components/ManageAnnualReports/ManageAnnualReports";
export default {
  components: {
    ManageAnnualReports,
  },
  created() {},
};
</script>
